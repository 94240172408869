<template>
  <div>
    <div style="display: flex; flex-wrap: wrap">
      <div
        v-for="(chartData, index) in chartDatas"
        :id="'myChart' + index"
        class="mychart"
      ></div>

      <!-- <button @click="add">add</button> -->
    </div>
    <el-empty v-if="chartDatas.length == 0" description="暂无数据"></el-empty>
  </div>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";
export default {
  data() {
    return {
      wjdataList: [],
      chartDatas: [],
      patientInfo: "",
      dataList: "",
      active: "日程",
      username: "",
      password: "",
      diagnosis: "",
      username1: "",
      showSelect: false,
      fanganList: "",
      fanganInfo: "",
      password1: "",
      choiceDate: "",
      type: "calendar",
      imgCode: "",
      verify: "",
      imgSelect: false,
      minDate: new Date(),
      maxDate: new Date(),
      defaultDate: new Date(),
      // dutyList: [],
      count: 0,
      yearCount: 0,
      year: new Date().getFullYear(),
      mounth: new Date().getMonth(),
      nowDay: new Date().getDate(),
      planData: [],
      minDate2: new Date(2020, 0, 1),
      maxDate2: new Date(2100, 0, 31),
      startTime: "",
      colorArr: [
        ["#C3E6FF", "#EAF6FF"],
        ["#CEDBFF", "#E5ECFF"],
        ["#FFECD2", "#FFF2E0"],
        ["#C3E6FF", "#EAFFFB"],
        ["#FFD5E1", "#FFDBE6"],
        ["#CEDBFF", "#E5ECFF"],
      ],
      endTime: "",
      showTime: false, //显示区间时间
      rangeTime: "", //显示区间时间
      caseList: [], //病历列表
      xtList: [],
      xtList2: [],
      xAxisList: [],
      lastXt: "",
      xtListAll: [],
    };
  },

  watch: {},

  mounted() {
    this.getList();
    // this.add()
  },

  methods: {
    async getQsInfo(id) {
      var _this = this;
      return new Promise((resolve, reject) => {
        let params = {
          id: id,
          stringParam1: id,
        };
        _this
          .$httpAes({
            url: _this.$http.adornUrl("/vfTableInfo/info"),
            method: "post",
            data: params,
          })
          .then(({ data }) => {
            if (data.status && data.data && data.data.tableContent) {
              let widgetList = JSON.parse(data.data.tableContent).widgetList;
              let arr = [];
              if (widgetList.length) {
                widgetList.forEach((ele) => {
                  if (
                    ele.type == "input" ||
                    ele.type == "slider" ||
                    ele.type == "radio" ||
                    ele.type == "checkbox" ||
                    ele.type == "number" ||
                    ele.type == "textarea" ||
                    ele.type == "time" ||
                    ele.type == "time-range" ||
                    ele.type == "date" ||
                    ele.type == "date-range" ||
                    ele.type == "select" ||
                    ele.type == "rate" ||
                    ele.type == "rich-editor"
                  ) {
                    arr.push(ele);
                  }
                });
              }
              resolve(arr);
            }
          });
      });
    },
    //获取列表
    async getList() {
      var that = this;
      this.$http({
        url: this.$http.adornUrl("/indicatorChart/getIndicatorCharts"),
        method: "get",
        params: {
          patienceId: JSON.parse(localStorage.patientInfo).patience_id,

          teamsId: JSON.parse(localStorage.currentTeam).id,
        },
      }).then((res) => {
        for (let index = 0; index < res.data.data.length; index++) {
          let flag = false;
          for (let dataIndicator of res.data.data[index].dataIndicators) {
            if (
              dataIndicator.type == 1 &&
              dataIndicator.vfWriteInfos &&
              dataIndicator.vfWriteInfos.length > 0
            ) {
              flag = true;
            }
            if (
              dataIndicator.type == 2 &&
              dataIndicator.patienceSigns &&
              dataIndicator.patienceSigns.length > 0
            ) {
              flag = true;
            }
          }

          if (flag) {
            that.chartDatas.push(index);
          } else {
            res.data.data.splice(index, 1);

            index--;
          }
        }

        setTimeout(() => {
          for (let index in res.data.data) {
            let xAxisList = [];

            let seriesArr = [];

            for (let dataIndicator of res.data.data[index].dataIndicators) {
              let seriesData = [];
              if (
                dataIndicator.type == 1 &&
                dataIndicator.vfWriteInfos &&
                dataIndicator.vfWriteInfos.length > 0
              ) {
                dataIndicator.vfWriteInfos.sort(function (a, b) {
                  return a.createTime < b.createTime ? 1 : -1;
                });
                for (let item of dataIndicator.vfWriteInfos) {
                  seriesData.push(item.fillContent);
                }
              }
              if (
                dataIndicator.type == 2 &&
                dataIndicator.patienceSigns &&
                dataIndicator.patienceSigns.length > 0
              ) {
                dataIndicator.patienceSigns.sort(function (a, b) {
                  return a.createTime < b.createTime ? 1 : -1;
                });
                for (let item of dataIndicator.patienceSigns) {
                  if (dataIndicator.cellName == "收缩压") {
                    seriesData.push(item.systolicPressure);
                  } else if (dataIndicator.cellName == "舒张压") {
                    seriesData.push(item.diastolicPressure);
                  } else {
                    seriesData.push(item.signValue);
                  }
                }
              }

              let obj = {
                seriesData: seriesData,
                name: dataIndicator.chartDisplayName,
                unitName: dataIndicator.unitName,
                labelArr: false,
              };
              if (dataIndicator.cellName == "单选") {
                that.getQsInfo(dataIndicator.infoId).then((res) => {
                  for (let item of res) {
                    if (item.type == "radio") {
                      obj.labelArr = item.options.optionItems;
                    }
                  }
                });
              }
              if (dataIndicator.cellName == "下拉选") {
                that.getQsInfo(dataIndicator.infoId).then((res) => {
                  for (let item of res) {
                    if (item.type == "select") {
                      obj.labelArr = item.options.optionItems;
                    }
                  }
                });
              }
              seriesArr.push(obj);
            }
            if (
              res.data.data[index].dataIndicators[0].type == 1 &&
              res.data.data[index].dataIndicators[0].vfWriteInfos &&
              res.data.data[index].dataIndicators[0].vfWriteInfos.length > 0
            ) {
              for (let item of res.data.data[index].dataIndicators[0]
                .vfWriteInfos) {
                xAxisList.push(moment(item.createTime).format('YYYY-MM-DD'));
              }
            }
            if (
              res.data.data[index].dataIndicators[0].type == 2 &&
              res.data.data[index].dataIndicators[0].patienceSigns &&
              res.data.data[index].dataIndicators[0].patienceSigns.length > 0
            ) {
              for (let item of res.data.data[index].dataIndicators[0]
                .patienceSigns) {
                xAxisList.push(moment(item.createTime).format('YYYY-MM-DD'));
              }
            }
            xAxisList.sort(function (a, b) {
              return a > b ? 1 : -1;
            });

            let dom = document.getElementById("myChart" + index);

            let myChart = echarts.init(dom); // 初始化echarts实例
            that.option = {
              title: {
                text: res.data.data[index].chartName,
                // left: "center",
                textAlign: "left",
                padding: [
                  20, // 上
                  24, // 右
                  5, // 下
                  24, // 左
                ],
              },
              backgroundColor: "#F7F9FB", //

              grid: {
                //与绝对定位相似，top，left，right，bottom 设定是根据上级盒子宽高来计算

                top: "15%",

                left: "5%",

                right: "5%",

                bottom: "64",
              },
              // tooltip: {
              // 	trigger: 'axis',

              // },
              //提示框
              tooltip: {
                trigger: "axis",
                formatter: (params) => {
                  console.log(params);
                  var relVal = "";
                  for (var i = 0, l = params.length; i < l; i++) {
                    if (params[i].value) {
                      if (seriesArr[i].labelArr) {
                        relVal +=
                          seriesArr[i].name +
                          " " +
                          params[i].marker +
                          " " +
                          seriesArr[i].labelArr[params[i].value - 1].label +
                          " " +
                          seriesArr[i].unitName +
                          "<br/>";
                      } else {
                        relVal +=
                          seriesArr[i].name +
                          " " +
                          params[i].marker +
                          " " +
                          params[i].value +
                          " " +
                          seriesArr[i].unitName +
                          "<br/>";
                      }
                    } else {
                      relVal +=
                        seriesArr[i].name +
                        " " +
                        params[i].marker +
                        " " +
                        "无数据" +
                        " " +
                        seriesArr[i].unitName +
                        "<br/>";
                    }
                  }
                  return relVal;
                },
              },

              legend: {
                data: [],
                icon: "circle",
                bottom: 20,
              },

              xAxis: {
                type: "category",

                data: xAxisList,
                // boundaryGap: false,
              },
              yAxis: {
                type: "value",
                // max: 20,
                min: 0,
                splitNumber: 4,
              },
              toolbox: {
                feature: {
                  saveAsImage: {
                    icon:
                      "image://" +
                      require("../../assets/img/downloadChart.png"),
                  },
                },
                right: 24,
                top: 20,
              },
              dataZoom: [
                {
                  type: "inside",
                  xAxisIndex: [0],
                  start: 0,
                  end: 100,
                },
              ],
              series: [],
            };
            for (let item of seriesArr) {
              let obj = {
                name: item.name,
                data: item.seriesData,
                type: "line",
                symbol: "circle",
                smooth: true,

                unitName: item.unitName,
                connectNulls: true,
                areaStyle: {
                  opacity: 0.5,
                  normal: {
                    //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: this.colorArr[index % 6][0], // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: this.colorArr[index % 6][1], // 100% 处的颜色
                      },
                    ]), //背景渐变色
                  },
                },
              };

              //	unitName:dataIndicator.unitName,
              that.option.legend.data.push(item.name);
              that.option.series.push(obj);
            }

            // 设置实例参数
            myChart.setOption(that.option);
            window.addEventListener("resize", function () {
              myChart.resize();
            });
          }
        }, 3000);
      });
    },

    initChart(row) {
      if (row) {
        this.list2 = this.list1.filter((item) => item.signType == row.signType);
        this.list2.sort(
          (a, b) => new Date(a.createDate) - new Date(b.createDate)
        );
        this.list2 = this.list2.slice(0, 20);
        const x = this.list2.map((item) => item.createDate);
        const y = this.list2.map((item) => item.signValue);
        var chartDom = document.getElementById("main");
        var myChart = echarts.init(chartDom);
        let unitName = this.list.length
          ? this.list2[0].signTypeName + "(" + this.list2[0].unit + ")"
          : "";
        const option = {
          title: {
            text: unitName,
          },
          tooltip: {
            trigger: "axis",
            formatter:
              "{b} <br/> {a}: {c}" + this.list.length ? this.list2[0].unit : "", //提示框的内容
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: x,
            interval: 0,
            axisLabel: {
              interval: 0, //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              rotate: -45, //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
            },
          },
          grid: {
            top: 50,
            left: 30,
            right: 50,
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              name: row.signTypeName,
              type: "line",
              color: ["#1890FF"],
              symbol: "circle",
              areaStyle: {
                normal: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgba(91, 143, 249, 1)", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "rgba(148, 194, 253, 0.05)", // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  },
                },
              },

              data: y,
            },
          ],
        };
        //修改标题
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mychart {
  width: calc(50% - 12px);

  height: 467px;
  margin-bottom: 24px;
  background: #f7f9fb;
}
.mychart:nth-child(odd) {
  margin-right: 24px;
}
</style>
